import { Component, DestroyRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { delay, filter, fromEvent, noop, startWith, tap } from 'rxjs';

import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { environment } from '@env/environment';
import { VersionCheckService, PusherService, HubspotChatbotService } from 'app/core';
import { LayoutService } from './layout/layout.service';
import UrlUtils from './shared/Utils/url.utils';
import { datadogRum } from '@datadog/browser-rum';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends OnDestroyMixin implements OnInit {
  public showProgressBar: boolean = false;
  newUi = true;
  private readonly minScreenWidthThreshold = 1300;

  constructor(
    private readonly _pusherService: PusherService,
    private readonly _hubspotChatbotService: HubspotChatbotService,
    private readonly _versionCheckService: VersionCheckService,
    private readonly layoutService: LayoutService,
    private readonly _router: Router,
    @Inject(DOCUMENT) private readonly docRef: Document,
    private readonly renderer2: Renderer2,
    private readonly destroyRef: DestroyRef,
  ) {
    super();
  }

  ngOnInit(): void {
    console.log(`Vercel POC`);

    fromEvent(this.docRef.defaultView, 'resize')
      .pipe(startWith(null), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.docRef?.defaultView?.innerWidth < this.minScreenWidthThreshold) {
          this.renderer2.setStyle(this.docRef.body, 'zoom', '90%');
        } else {
          this.renderer2.setStyle(this.docRef.body, 'zoom', '100%');
        }
      });

    this._pusherService.registerForPusher();
    this._hubspotChatbotService.loadChatbotScript();
    this.toggleGlobalNavigationLoading();

    // Layout using new ui
    const tsNewUi = UrlUtils.getFromLocalStorage('ts-new-ui-v2');
    this.layoutService.newUi = this.newUi = ['', 'true'].includes(tsNewUi);

    if (environment.production) {
      this._versionCheckService.initVersionCheck(environment.versionCheckURL);
    }

    if (['qaUI', 'stagingUI', 'productionUI'].includes(environment.env)) {
      if (environment.production) {
        this._versionCheckService.initVersionCheck(environment.versionCheckURL);

        datadogRum.init(environment.dataDog);
      }
    }

    // Segment/Intercom Analytics
    this._router.events.pipe(untilComponentDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        analytics.page(event.url);
      }
    });
  }

  private toggleGlobalNavigationLoading(): void {
    this._router.events
      .pipe(
        untilComponentDestroyed(this),
        delay(100),
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError,
        ),
        tap((event) => {
          if (event instanceof NavigationStart) this.showProgressBar = true;
          else this.showProgressBar = false;
        }),
      )
      .subscribe(noop);
  }
}
