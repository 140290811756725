import {
  LicenseManager,
  CellEditingStoppedEvent,
  GridApi,
  GetContextMenuItemsParams,
  RowGroupingDisplayType,
  FirstDataRenderedEvent,
  SelectionChangedEvent,
  GridOptions,
  GetDataPath,
} from 'ag-grid-enterprise';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef, GridReadyEvent, CellClickedEvent } from 'ag-grid-community';
import { AgGridAngular, AgGridModule } from 'ag-grid-angular';
import { TSIconModule } from '../ts-icon';
import { TableActionsRendererComponent } from './renderer-components/table-actions-renderer.component';
import { TsTableService } from '../services';
import { ColumnDef, TsTableOptions } from './ts-table.model';
import { TableActions } from '../cross-cutting/models/table-actions';
import { isEqual } from 'lodash';
import { TableOpenDetailsRendererComponent } from '@shared/components/ts-table-renderers/table-open-details-renderer.component';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-064514}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{TermSheet}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{TermSheet}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{TermSheet}_need_to_be_licensed___{TermSheet}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{2_August_2025}____[v3]_[01]_MTc1NDA4OTIwMDAwMA==a6ae0c4c52035689fb1c57902d2bf306',
);

@Component({
  selector: 'ts-table',
  templateUrl: './ts-table.component.html',
  styleUrls: ['./ts-table.component.scss'],
  standalone: true,
  imports: [TSIconModule, AgGridAngular, AgGridModule, TableActionsRendererComponent],
})
export class TsTableComponent implements OnInit {
  @Input() readOnly: boolean = false;
  @Input() getDataPath!: GetDataPath;

  private _columnDefs: ColumnDef[] = [];
  @Input()
  set columnDefs(value: ColumnDef[]) {
    if (!isEqual(this._columnDefs, value)) {
      this._columnDefs = value;
      this.setColDefs();
    }
  }

  get columnDefs(): ColumnDef[] {
    return this._columnDefs;
  }

  private _gridOptions: GridOptions = {
    getDataPath: this.getDataPath || undefined,
    getContextMenuItems: (params: GetContextMenuItemsParams) => {
      return [];
    },
  };

  @Input()
  set gridOptions(value: GridOptions) {
    this._gridOptions = {
      ...this._gridOptions,
      ...value,
    };
  }

  get gridOptions(): GridOptions {
    return this._gridOptions;
  }

  public groupDisplayType: RowGroupingDisplayType = 'groupRows';

  @Input() set rowData(value: any[]) {
    if (!isEqual(this._rowData, value)) {
      this._rowData = value;
    }
  }

  get rowData() {
    return this._rowData;
  }

  private _rowData: any[] = [];

  @Input() set loading(val: boolean) {
    if (this.gridApi) {
      this.gridApi.setGridOption('loading', val);
    }
  }

  @Input() tsTableOptions: TsTableOptions = {};
  @Input() rowSelection = 'multiple';
  @Input() hasActionsColumn: boolean = false;
  @Input() hasCheckboxColumn: boolean = false;
  @Input() hasOpenDetailsColumn: boolean = false;
  @Input() checkboxSelection: boolean = false;
  @Input() headerCheckboxSelection: boolean = true;
  @Input() suppressDragLeaveHidesColumns: boolean = true;
  @Input() actions: TableActions[] = [];
  @Input() relatedToRedirectConfig: any = {};
  @Input() suppressRowClickSelection: boolean = true;
  @Input() data?: any;
  @Input() defaultColDef?: ColDef;
  @Input() treeData: boolean = false;
  @Input() autoGroupColumnDef: any;
  @Input() checkboxColumnChanged: EventEmitter<boolean>;
  @Input() autoHeight: boolean = true;

  @Output() onColumnMove: EventEmitter<any> = new EventEmitter();
  @Output() onRecordClick: EventEmitter<any> = new EventEmitter();
  @Output() cellClicked = new EventEmitter<CellClickedEvent>();
  @Output() cellEditingStopped = new EventEmitter<CellEditingStoppedEvent>();
  @Output() sortChanged: EventEmitter<any> = new EventEmitter();
  @Output() dragStopped: EventEmitter<any> = new EventEmitter();
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();
  @Output() openDetails: EventEmitter<any> = new EventEmitter();
  @Output() onFirstDataRendered: EventEmitter<any> = new EventEmitter();
  @Output() gridReady: EventEmitter<any> = new EventEmitter();
  @Output() cellValueChanged: EventEmitter<any> = new EventEmitter();
  @Output() rowGroupOpened: EventEmitter<any> = new EventEmitter();
  @Output() columnResized: EventEmitter<any> = new EventEmitter();
  @Output() gridColumnsChanged: EventEmitter<any> = new EventEmitter();

  colDefs: ColDef[] = [];

  private gridApi!: GridApi<any>;
  private columnMoved: boolean = false;

  constructor(private tsTableService: TsTableService) {}

  static get ActionsColumnName(): string {
    return 'actions';
  }

  static getActionColumnDef(actions: TableActions[]): ColDef {
    return {
      headerName: '',
      field: TsTableComponent.ActionsColumnName,
      pinned: 'right',
      lockPinned: true,
      sortable: false,
      lockPosition: 'right',
      resizable: false,
      suppressMovable: true,
      width: 50,
      suppressHeaderMenuButton: true,
      cellRenderer: TableActionsRendererComponent,
      cellRendererParams: {
        actions: actions,
      },
      cellStyle: {
        overflow: 'visible',
        'text-overflow': 'clip',
        'white-space': 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    };
  }

  ngOnInit(): void {
    this.setColDefs();
    this.checkboxColumnChanged?.subscribe((hasCheckbox) => {
      this.hasCheckboxColumn = hasCheckbox;
      this.setColDefs();
    });
  }

  handleRecordClick(event: any): void {
    this.onRecordClick.emit(event);
  }

  handleColumnMove(event: any): void {
    if (event.finished) {
      this.onColumnMove.emit(event);
    }
  }

  onDragStopped(event: any): void {
    this.dragStopped.emit(event);
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
    if (event.newValue === undefined) {
      event.node.setDataValue(event.colDef?.field, event.oldValue);
      return;
    }

    if (event.colDef?.field === 'tag_list') {
      this.cellEditingStopped.emit(event);
    }
    const normalizeValue = (value: any): any => {
      if (!isNaN(parseFloat(value)) && isFinite(value)) {
        return parseFloat(value);
      }
      return value;
    };

    const oldValueNormalized = normalizeValue(event.oldValue);
    const newValueNormalized = normalizeValue(event.newValue);
    if (Array.isArray(event.value)) {
      isEqual(event.oldValue, event.newValue) ? null : this.cellEditingStopped.emit(event);
    } else if (oldValueNormalized !== newValueNormalized) {
      this.cellEditingStopped.emit(event);
    }
  }

  onSortChanged(event: any) {
    this.sortChanged.emit(event);
  }

  setColDefs(): void {
    this.colDefs = this.tsTableService.convertFieldDefsToColDefs(
      this.columnDefs,
      this.relatedToRedirectConfig,
      this.data,
      null,
      this.readOnly,
    );

    if (this.hasCheckboxColumn) {
      this.setCheckboxColumn();
    }

    this.setOpenDetailsColumn();
    this.setActionsColumn();
  }

  setCheckboxColumn(): void {
    if (this.hasCheckboxColumn && this.colDefs[0]?.field !== 'checkbox') {
      this.colDefs?.unshift({
        headerName: '',
        field: 'checkbox',
        width: 50,
        headerCheckboxSelection: this.headerCheckboxSelection,
        checkboxSelection: (params) => {
          return !params.data?.isCategory;
        },
        pinned: 'left',
        lockPinned: true,
        sortable: false,
        resizable: false,
        suppressMovable: true,
        suppressMenu: true,
        suppressSizeToFit: true,
      });
    }
  }

  setActionsColumn(): void {
    if (
      this.actions.length > 0 &&
      this.colDefs.length > 0 &&
      this.colDefs[this.colDefs.length - 1].field !== 'actions'
    ) {
      this.colDefs.push(TsTableComponent.getActionColumnDef(this.actions));
    }
  }

  setOpenDetailsColumn(): void {
    if (this.hasOpenDetailsColumn && !this.colDefs.some((col) => col.field === 'openDetails')) {
      this.colDefs.unshift({
        headerName: '',
        field: 'openDetails',
        pinned: 'left',
        lockPinned: true,
        sortable: false,
        resizable: false,
        suppressMovable: true,
        width: 50,
        suppressHeaderMenuButton: true,
        cellRenderer: TableOpenDetailsRendererComponent,
        cellRendererParams: {
          onOpenDetails: (data) => this.onOpenDetails(data),
        },
        cellStyle: {
          overflow: 'visible',
          'text-overflow': 'clip',
          'white-space': 'nowrap',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      });
    }
  }

  onCellClicked(event: CellClickedEvent): void {
    this.cellClicked.emit(event);
  }

  sizeColumnsToFit(): void {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  onSelectionChanged(event: SelectionChangedEvent): void {
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectionChanged.emit(selectedRows);
  }

  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    this.gridReady.emit(params);
  }

  onOpenDetails(data: any): void {
    this.openDetails.emit(data);
  }

  firstDataRendered(params: FirstDataRenderedEvent): void {
    this.onFirstDataRendered.emit(params);
  }

  onCellValueChanged(event: any): void {
    this.cellValueChanged.emit(event);
  }

  onRowGroupOpened(event: any) {
    this.rowGroupOpened.emit(event);
  }

  onColumnResized(event: any) {
    if (event.finished) {
      this.columnResized.emit(event);
    }
  }
}
