export const environment = {
  production: true,
  baseUrl: 'https://app.termsheetqa.com',
  apiVersion: 'api/v2',
  versionCheckURL: 'https://dashboard.termsheetqa.com/version.json',
  cookieDomain: 'termsheetdev.com',
  appDomain: 'termsheetdev.com',
  tilesApi: {
    url: 'https://staging.parceled.com',
    authToken: '50ED635ABAB395CAFA86',
  },
  google: {
    streetViewKey: 'AIzaSyDv7GjiEXfaV-oWTZBL5gJcwniQTBElk0I',
  },
  mapbox: {
    accessToken: 'pk.eyJ1Ijoicm9nZXNtaXRoIiwiYSI6ImNqdHB2OHg5azAwbzA0M3BlZ3dxanZ2NXcifQ.PBWyfzuqPZMB6EaL6mQdvQ',
  },
  env: 'devUI',
  dataDog: {
    env: 'dev',
    applicationId: '',
    clientToken: '',
    site: '',
    service: '',
  },
  pusher: {
    instanceLocator: 'v1:us1:293dfee0-776e-4024-accd-63f80b5d820f',
    instanceId: 'd5149367-2d46-462f-82c3-8e838a3b754f',
    channelApp: {
      key: '92535bc5500134da1eaa',
      cluster: 'us2',
    },
  },
  rollbar: {
    accessToken: '1b1a90d4408b492f88af7ce6b2f9802a',
    codeVersion: '92a48abf4',
    code_version: '92a48abf4',
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: 'qaUI',
    source_map_enabled: true,
    guess_uncaught_frames: true,
  },
  analytics: {
    segmentWriteKey: 'XJIvenWeIi1EeCVOxV3ad5F1YYPXOoNH',
  },
  syncfusion: {
    licenseKey: 'Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH5fd3VXQ2ddVUB1XUE=',
  },
  hubspot: {
    chatbotScriptId: '6152449',
  },
  ethanAbilities: {
    apiKey: 'ea-aOzGvwRxpv6KI0G3zgK088i6vw8qWUxNj-aLEheA5Ic',
  },
  enableReduxDevtools: true,
  ethanAssistant: {
    authorization: 'Bearer ea-U3Aa5O2MoE6ELu73g8OkBWhJ22Np1PWflpJYLKx-X9Y',
    url: 'https://ai-assistant.termsheet.com/api/v1/assistant/stream',
    env: 'qa',
  },
};
